
import React from "react"
import { Link } from "gatsby"
import "./styles.scss"

export const Footer = () => {
  return (
    <footer className="et-l et-l--footer">
      <div className="et_builder_inner_content et_pb_gutters3"><div className="et_pb_section et_pb_section_0_tb_footer et_pb_with_background et_section_regular section_has_divider et_pb_bottom_divider et_pb_top_divider optml-bg-lazyloaded">
        <div className="et_pb_top_inside_divider"></div>
        <div className="et_pb_row et_pb_row_0_tb_footer et_pb_row_1-4_1-4_1-2">
          <div className="et_pb_column et_pb_column_1_4 et_pb_column_0_tb_footer  et_pb_css_mix_blend_mode_passthrough">
            <a href="tel:13128884488">
              <div className="et_pb_module et_pb_blurb et_pb_blurb_0_tb_footer et_clickable et_pb_text_align_center et_pb_blurb_position_top et_pb_bg_layout_dark optml-bg-lazyloaded">
                <div className="et_pb_blurb_content">
                  <div className="et_pb_main_blurb_image">
                    <span className="phone-icon-et et-pb-icon et-pb-icon-circle" />
                  </div>
                  <div className="et_pb_blurb_container">
                    <h4 className="et_pb_module_header"><span>Phone</span></h4>
                    <div className="et_pb_blurb_description">
                      <p data-testid="phone-number">312-888-4488</p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="et_pb_column et_pb_column_1_4 et_pb_column_1_tb_footer  et_pb_css_mix_blend_mode_passthrough">
            <a href="mailto:orders@typenex.com">
              <div className="et_pb_module et_pb_blurb et_pb_blurb_1_tb_footer et_clickable et_pb_text_align_center et_pb_blurb_position_top et_pb_bg_layout_dark optml-bg-lazyloaded">
                <div className="et_pb_blurb_content">
                  <div className="et_pb_main_blurb_image">
                    <span className="email-icon-et et-pb-icon et-pb-icon-circle" />
                  </div>
                  <div className="et_pb_blurb_container">
                    <h4 className="et_pb_module_header"><span>Email</span></h4>
                    <div className="et_pb_blurb_description">
                      <p data-testid="email-address">orders@typenex.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="footer-extras">
          <div data-testid="copyright" className="copyright">
            &copy; Copyright {new Date().getFullYear()} ARC Medical, Inc. All Rights Reserved
          </div>
          <div>
            <p className="footer-links">
              <Link to="/terms-of-use/">Terms of Use</Link>&nbsp;|&nbsp;
              <Link to="/privacy-policy/">Privacy Policy</Link>&nbsp;|&nbsp;
              <Link to="/disclaimer/">Disclaimer</Link></p>
          </div>
        </div>
      </div>
      </div>
    </footer>
  )
}

export default Footer